let contractAddress = "TRohfcXHAohqRK4UKXrHEBoUPpzP8dDqc2";
let tokenAddress = "TXLAQ63Xg1NAzckPwKHvzw7CSEmLMEqcdj";
let isTestnet = true;
export default {
  tronApiKey: "3a27d39d-b2d9-417c-a340-e36085f6a077",
  // contractAddress: "TRohfcXHAohqRK4UKXrHEBoUPpzP8dDqc2",
  // tokenAddress: "TXLAQ63Xg1NAzckPwKHvzw7CSEmLMEqcdj",
  // host: "https://nile.trongrid.io/",
  getContractAddress: function () {
    return contractAddress;
  },
  setContractAddress: function (address) {
    contractAddress = address;
  },
  getTokenAddress: function () {
    return tokenAddress;
  },
  setTokenAddress: function (address) {
    tokenAddress = address;
  },
  getHost: () => {
    if (isTestnet) {
      return "https://nile.trongrid.io/";
    } else {
      return "https://api.trongrid.io/";
    }
  },
  setIsTestNet: (_value) => {
    isTestnet = _value;
  },
  getIsTestnet: () => {
    return isTestnet;
  },
};
