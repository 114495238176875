import React, { useState, useEffect } from "react";
import { Container, Row, Col, Button, Alert, Form } from "react-bootstrap";
import "bootstrap/dist/css/bootstrap.min.css";
import "./UserPanel.css";
import constants from "../utils/constants";
import { multiLevelAbi } from "../utils/multiLevelAbi";
import { toast } from "react-toastify";
import helper from "../utils/helper";
import MyCountDown from "./MyCountDown";
import TronWeb from "tronweb";
await helper.syncDatabase();
const UserPanel = () => {
  const [contract, setContract] = useState(null);
  const [readContract, setReadContract] = useState(null);
  const [tronWebRemote, setTronWebRemote] = useState(null);
  const [tronWeb, setTronWeb] = useState(null);
  const [deposited, setDeposited] = useState(false);
  const [isDepositing, setIsDepositing] = useState(false);
  const [isWithdrawing, setIsWithdrawing] = useState(false);
  const [walletConnected, setWalletConnected] = useState(false);
  const [userAddress, setUserAddress] = useState(
    "TSCe4dRgQ3MiktxBFtf2tJ4VPuDq2KudG2"
  );
  const [availableBalance, setAvailableBalance] = useState(0);
  const [lastReferralTimeS, setLastReferralTimeS] = useState(0);
  const [referrerAddress, setReferrerAddress] = useState("");
  const [registrationTimeS, setRegistrationTimeS] = useState(0);
  const [missionTwoCompleted, setMissionTwoCompleted] = useState(false);
  const [missionFailed, setMissionFailed] = useState(false);
  const [referralCount, setReferralCount] = useState(0);

  const intervalForDeposit = async () => {
    console.log("interval called");
    setTimeout(async () => {
      let status = await updateDepositStatus();
      if (!status) {
        intervalForDeposit();
      }
    }, 2000);
  };

  const curTime = () => {
    console.log("Cur time called");
    return Date.now() / 1000;
  };

  const handleWithdrawBalance = async () => {
    try {
      setIsWithdrawing(true);
      if (availableBalance < 700 || !missionTwoCompleted) return;
      if (helper.isSevenDaysAgo(lastReferralTimeS)) {
        toast.info("No referral in last 7 days");
        return;
      }
      let _approveResult = await contract.withdrawEarnings().send({
        feeLimit: 200_000_000,
        callValue: 0,
      });
      if (_approveResult) {
        toast.success("Transaction Sent");
      }
    } catch (error) {
      toast.error(error.toString());
    } finally {
      setIsWithdrawing(false);
    }
  };
  const handleDepositClick = async () => {
    try {
      setIsDepositing(true);
      if (!contract || !userAddress) {
        toast.info("Please connect your wallet first");
        return;
      }
      if (!referrerAddress || referrerAddress.trim().length == 0) {
        toast.info("Please enter referrer Address");
        return;
      }
      let _referrer = referrerAddress.trim();
      console.log(_referrer);
      let checkRegistered = await contract.isRegistered(_referrer).call();

      if (!checkRegistered) {
        // let checkRegistered = true;
        toast.info("Referrer Address Provided is invalid");
        return;
      }
      let _depositResult = await contract.deposit(_referrer).send({
        feeLimit: 300_000_000,
        callValue: 70000000,
      });
      if (_depositResult) {
        toast.success("Transaction Sent");
      }
      intervalForDeposit();
    } catch (error) {
      console.log(error);
      toast.error(error.toString());
    } finally {
      updateDepositStatus();
      setIsDepositing(false);
    }
  };
  async function connectWallet() {
    let tronLink = await window.tronLink;
    // console.log(tronLink);
    if (!tronLink) return;
    if (await tronLink.ready) {
      const _tron = new TronWeb({
        fullHost: constants.getHost(),
        headers: { "TRON-PRO-API-KEY": constants.tronApiKey },
      });
      _tron.setAddress(await tronLink.tronWeb.defaultAddress.base58);
      setTronWebRemote(_tron);
      setTronWeb(tronLink.tronWeb);
      setWalletConnected(true);
    } else {
      const res = await tronLink.request({ method: "tron_requestAccounts" });
      if (res.code === 200) {
        setTronWeb(tronLink.tronWeb);
        setWalletConnected(true);
      }
    }
  }
  async function getMyBalance() {
    try {
      if (!contract || !userAddress) return;
      let _balance = await contract.balance(userAddress).call();
      setAvailableBalance(parseInt(_balance));
    } catch (error) {
      console.log(error);
    }
  }
  async function updateUserDetails() {
    try {
      if (!tronWeb) return;

      // const _tron = new TronWeb({
      //   fullHost: "https://api.trongrid.io",
      //   headers: { "TRON-PRO-API-KEY": "your api key" },
      //   privateKey: "your private key",
      // });
      // await tronWeb.setHeader({ "TRON-PRO-API-KEY": constants.tronApiKey });
      let userAddress = await tronWeb.defaultAddress.base58;
      setUserAddress(userAddress);
      let _contract = await tronWeb.contract(
        multiLevelAbi,
        constants.getContractAddress()
      );
      setContract(_contract);
      _contract = await tronWebRemote.contract(
        multiLevelAbi,
        constants.getContractAddress()
      );
      setReadContract(_contract);
    } catch (error) {
      console.log(error);
    }
  }

  async function updateDepositStatus() {
    if (!contract || !userAddress) return;
    let _deposited = await readContract.isRegistered(userAddress).call();
    setDeposited(_deposited);
    if (_deposited) {
      updateRegistationTime();
    }
    return _deposited;
  }

  async function updateMissionStatus() {
    if (!contract || !userAddress) return;
    let _result = await readContract.missionCompleted(userAddress).call();
    let _failResult = await readContract.missionFailed(userAddress).call();
    setMissionFailed(_failResult);
    setMissionTwoCompleted(_result);
  }

  async function updateRegistationTime() {
    if (!contract || !userAddress) return;
    console.log("here");
    let _result = await readContract.registrationTimeS(userAddress).call();
    console.log(parseInt(_result));
    setRegistrationTimeS(parseInt(_result));
  }
  async function updateLastReferralTime() {
    if (!contract || !userAddress) return;
    let _result = await readContract.getMyLastReferredTimeS().call();
    setLastReferralTimeS(parseInt(_result));
  }

  async function updateRefferalCount() {
    if (!contract || !userAddress) return;
    let res = await contract.getMyReferredCount().call();
    setReferralCount(parseInt(res));
  }

  async function syncDataWithContract() {
    updateDepositStatus();
    updateMissionStatus();
    updateLastReferralTime();
    getMyBalance();
    updateRegistationTime();
    updateRefferalCount();
  }

  useEffect(() => {
    syncDataWithContract();
  }, [readContract]);
  useEffect(() => {
    updateUserDetails();
  }, [tronWeb, walletConnected]);

  useEffect(() => {
    connectWallet();
  }, []);

  useEffect(() => {
    console.log(registrationTimeS);
  }, [registrationTimeS]);

  return (
    <Container>
      <Row className="justify-content-md-start mt-5">
        <Row>
          <h1>User Panel </h1>
          <Col>
            <Button
              variant="primary"
              onClick={connectWallet}
              disabled={walletConnected}
              className="mb-3"
            >
              {walletConnected ? userAddress : "Connect Wallet"}
            </Button>
          </Col>
        </Row>
        <Col>
          <h5>Deposit Section:</h5>
          <ul>
            <li>By Making a deposit , you are registered as a user</li>
            <li> </li>
            <li>Fee for deposit : 70 TRX</li>
          </ul>
          {/* Approve Deposit Button */}
          {/* <Button
            variant="success"
            onClick={handleApproveClick}
            disabled={deposited || isApproving || approved}
          >
            {approved ? "Approved" : "Approve USDT"}
          </Button>  */}
          <Form.Group className="mb-3 mt-3" controlId="formBasicEmail">
            <Form.Label>Referrer Address</Form.Label>
            <Form.Control
              maxLength={100}
              style={{
                maxWidth: "400px",
              }}
              type="text"
              onChange={(event) => {
                setReferrerAddress(event.target.value);
              }}
              placeholder="TX..."
            />
            <Form.Text className="text-muted">
              Referrer address is required to Register
            </Form.Text>
          </Form.Group>
          <Button
            variant="success"
            onClick={handleDepositClick}
            disabled={
              deposited ||
              isDepositing ||
              !referrerAddress ||
              referrerAddress.length == 0
            }
          >
            {deposited ? "Deposited" : "Deposit TRX"}
          </Button>
          {/* Descriptions */}
          {/* <div className="mt-4">
            <h5>Approve Deposit:</h5>
            <p>Click the button to approve the deposit.</p>

            <h5>Deposit Status:</h5>
            <p>View the status of your deposit approval.</p>

            <h5>Connect Wallet:</h5>
            <p>Click the button to connect your wallet for transactions.</p>
          </div> */}
        </Col>
        <Col>
          <h5 className="">Withdraw Section:</h5>
          <ul>
            <li>A minimum of 700 TRX is required for withdrawal</li>
            <li>
              Mission 1 and Mission 2 needs to be completed to be able to
              withdraw
            </li>
            <li>
              {" "}
              You need to have atlease one user referred in last 7 days to
              withdraw
            </li>
          </ul>
          <Form.Group className="mb-3 mt-3">
            <Form.Label>Balance : </Form.Label>
            <Form.Control
              readOnly
              maxLength={100}
              style={{
                display: "inline-block",
                marginLeft: "10px",
                maxWidth: "200px",
              }}
              value={availableBalance.toString()}
              type="text"
            />
          </Form.Group>
          <Form.Group className="mb-3 mt-3">
            <Form.Label>Last Referral Time (UNIX Seconds) : </Form.Label>
            <Form.Control
              readOnly
              maxLength={100}
              style={{
                display: "inline-block",
                marginLeft: "10px",
                maxWidth: "200px",
              }}
              value={lastReferralTimeS.toString()}
              type="text"
            />
            <Form.Text className="text-muted ms-3">
              {helper.getTimeDifferenceTextFromUnixTimestamp(lastReferralTimeS)}
            </Form.Text>
          </Form.Group>

          <Form.Group className="mb-3 mt-3">
            <Form.Label> Time Left for Mission 1: </Form.Label>

            <Form.Text className="text-muted ms-3">
              {registrationTimeS &&
                lastReferralTimeS == 0 &&
                registrationTimeS + 7 * 24 * 60 * 60 > curTime() && (
                  <MyCountDown
                    endTime={registrationTimeS + 7 * 24 * 60 * 60}
                  ></MyCountDown>
                )}
              {registrationTimeS &&
                ((lastReferralTimeS == 0 &&
                  registrationTimeS + 7 * 24 * 60 * 60 < curTime()) ||
                  missionFailed) && (
                  <div
                    style={{
                      background: "red",
                      padding: "10px",
                      color: "white",
                      borderRadius: "4px",
                      fontWeight: "bold",
                    }}
                  >
                    Mission Failed
                  </div>
                )}
              {registrationTimeS &&
                lastReferralTimeS != 0 &&
                !missionFailed && (
                  <div
                    style={{
                      background: "green",
                      padding: "10px",
                      color: "white",
                      borderRadius: "4px",
                      fontWeight: "bold",
                    }}
                  >
                    Mission Completed
                  </div>
                )}
            </Form.Text>
          </Form.Group>

          <Form.Group className="mb-3 mt-3">
            <Form.Label> Time Left for Mission 2: </Form.Label>

            <Form.Text className="text-muted ms-3">
              {registrationTimeS &&
                missionTwoCompleted == false &&
                registrationTimeS + 49 * 24 * 60 * 60 > curTime() && (
                  <MyCountDown
                    endTime={registrationTimeS + 49 * 24 * 60 * 60}
                  ></MyCountDown>
                )}
              {registrationTimeS &&
                missionTwoCompleted == false &&
                registrationTimeS + 49 * 24 * 60 * 60 > curTime() && (
                  <div style={{ marginTop: "20px" }}>
                    Referral Left to Complete : &nbsp;&nbsp;{7 - referralCount}
                  </div>
                )}
              {registrationTimeS &&
                missionTwoCompleted == false &&
                registrationTimeS + 49 * 24 * 60 * 60 < curTime() && (
                  <div
                    style={{
                      background: "red",
                      padding: "10px",
                      color: "white",
                      borderRadius: "4px",
                      fontWeight: "bold",
                    }}
                  >
                    Mission Failed
                  </div>
                )}
              {registrationTimeS && missionTwoCompleted && (
                <div
                  style={{
                    background: "green",
                    padding: "10px",
                    color: "white",
                    borderRadius: "4px",
                    fontWeight: "bold",
                  }}
                >
                  Mission Completed
                </div>
              )}
            </Form.Text>
          </Form.Group>

          <Form.Group className="mb-3 mt-3">
            <Form.Label> Mission Status : </Form.Label>
            <Form.Text className="text-muted ms-3">
              {missionTwoCompleted ? "Completed" : "Pending"}
            </Form.Text>
          </Form.Group>

          <Button
            variant="success"
            onClick={handleWithdrawBalance}
            disabled={
              availableBalance < 700 ||
              !helper.isSevenDaysAgo(lastReferralTimeS) ||
              !missionTwoCompleted ||
              isWithdrawing
            }
          >
            Withdraw
          </Button>
        </Col>
      </Row>
    </Container>
  );
};

export default UserPanel;
