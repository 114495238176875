import constants from "./constants";
import controller from "./firebase";

export default {
  getTimeDifferenceTextFromUnixTimestamp: (lastReferralTimestamp) => {
    const currentTime = Math.floor(Date.now() / 1000); // Current time in Unix timestamp
    const referralTime = lastReferralTimestamp;

    const timeDiff = currentTime - referralTime;
    const days = Math.floor(timeDiff / (60 * 60 * 24));
    const hours = Math.floor((timeDiff % (60 * 60 * 24)) / (60 * 60));
    const minutes = Math.floor((timeDiff % (60 * 60)) / 60);

    if (days > 0) {
      return `${days} days ago`;
    } else if (hours > 0) {
      return `${hours} hours ago`;
    } else {
      return `${minutes} minutes ago`;
    }
  },
  isSevenDaysAgo: (timeS) => {
    const currentTime = Math.floor(Date.now() / 1000);
    const timeDiff = currentTime - timeS;
    const days = Math.floor(timeDiff / (60 * 60 * 24));
    return days >= 7;
  },
  syncDatabase: async () => {
    let token = await controller.getTokenAddress();
    let contract = await controller.getSmartContractAddress();
    let isTestnet = await controller.getIsTestnet();
    constants.setContractAddress(contract);
    constants.setIsTestNet(isTestnet);
    constants.setTokenAddress(token);
  },
};
