import React, { useState, useEffect } from "react";
import { Container, Row, Col, Button, Alert, Form } from "react-bootstrap";
import "bootstrap/dist/css/bootstrap.min.css";
import "./UserPanel.css";
import constants from "../utils/constants";
import { multiLevelAbi } from "../utils/multiLevelAbi";
import { toast } from "react-toastify";
import helper from "../utils/helper";
import MyCountDown from "./MyCountDown";
import TronWeb from "tronweb";
await helper.syncDatabase();
const Administration = () => {
  const UserType = {
    customer: 0,
    topUser: 1,
    initialUser: 2,
  };
  const [contract, setContract] = useState(null);
  const [readContract, setReadContract] = useState(null);
  const [tronWebRemote, setTronWebRemote] = useState(null);
  const [tronWeb, setTronWeb] = useState(null);
  const [walletConnected, setWalletConnected] = useState(false);
  const [isWithdrawing, setIsWithdrawing] = useState(false);
  const [userType, setUserType] = useState(0);
  const [userAddress, setUserAddress] = useState(
    "TSCe4dRgQ3MiktxBFtf2tJ4VPuDq2KudG2"
  );
  const [availableBalance, setAvailableBalance] = useState(0);

  const handleWithdrawBalance = async () => {
    try {
      setIsWithdrawing(true);
      if (userType == UserType.customer) {
        toast.info("You are not admin");
        return;
      }
      if (userType == UserType.topUser) {
        let _withdrawResult = await contract.withdrawTopUserFunds().send({
          feeLimit: 200_000_000,
          callValue: 0,
        });
        if (_withdrawResult) {
          toast.success("Withdraw Transaction Sent");
        }
      } else {
        let _withdrawResult = await contract.withdrawInitialUser().send({
          feeLimit: 200_000_000,
          callValue: 0,
        });
        if (_withdrawResult) {
          toast.success("Withdraw Transaction Sent");
        }
      }
      getMyBalance();
    } catch (error) {
      toast.error(error.toString());
    } finally {
      setIsWithdrawing(false);
    }
  };

  async function connectWallet() {
    let tronLink = await window.tronLink;
    if (!tronLink) return;
    if (await tronLink.ready) {
      const _tron = new TronWeb({
        fullHost: constants.getHost(),
        headers: { "TRON-PRO-API-KEY": constants.tronApiKey },
      });
      _tron.setAddress(await tronLink.tronWeb.defaultAddress.base58);
      setTronWebRemote(_tron);
      setTronWeb(tronLink.tronWeb);
      setWalletConnected(true);
    } else {
      const res = await tronLink.request({ method: "tron_requestAccounts" });
      if (res.code === 200) {
        setTronWeb(tronLink.tronWeb);
        setWalletConnected(true);
      }
    }
  }
  async function getMyBalance() {
    try {
      if (!contract || !userAddress) return;
      let _balance = await contract.balance(userAddress).call();
      setAvailableBalance(parseInt(_balance));
    } catch (error) {
      console.log(error);
    }
  }
  async function updateUserDetails() {
    try {
      if (!tronWeb) return;

      // const _tron = new TronWeb({
      //   fullHost: "https://api.trongrid.io",
      //   headers: { "TRON-PRO-API-KEY": "your api key" },
      //   privateKey: "your private key",
      // });
      // await tronWeb.setHeader({ "TRON-PRO-API-KEY": constants.tronApiKey });
      let userAddress = await tronWeb.defaultAddress.base58;
      setUserAddress(userAddress);
      let _contract = await tronWeb.contract(
        multiLevelAbi,
        constants.getContractAddress()
      );
      setContract(_contract);
      _contract = await tronWebRemote.contract(
        multiLevelAbi,
        constants.getContractAddress()
      );
      setReadContract(_contract);
    } catch (error) {
      console.log(error);
    }
  }
  async function updateUserType() {
    try {
      if (!userAddress || !tronWeb) return;
      let _topUser = await tronWeb.address.fromHex(
        await readContract.topUser().call()
      );
      console.log(_topUser);
      if (userAddress == UserType.topUser) {
        setUserType(UserType.topUser);
        return;
      }
      for (let i = 0; i < 6; i++) {
        let _intialUser = await tronWeb.address.fromHex(
          await readContract.initialUsers(i).call()
        );
        console.log(i, " ", _intialUser);
        if (userAddress == _intialUser) {
          setUserType(UserType.initialUser);
          return;
        }
      }
      setUserType(UserType.customer);
    } catch (error) {
      console.log(error);
    }
  }
  async function syncDataWithContract() {
    getMyBalance();
    updateUserType();
  }

  useEffect(() => {
    syncDataWithContract();
  }, [readContract]);
  useEffect(() => {
    updateUserDetails();
  }, [tronWeb, walletConnected]);

  useEffect(() => {
    connectWallet();
  }, []);

  return (
    <Container>
      <Row className="justify-content-md-start mt-5">
        <Row>
          <h1>Administration Panel </h1>
          <Col>
            <Button
              variant="primary"
              onClick={connectWallet}
              disabled={walletConnected}
              className="mb-3"
            >
              {walletConnected ? userAddress : "Connect Wallet"}
            </Button>
          </Col>
        </Row>
        {userType != UserType.customer ? (
          <Col>
            <Form.Group className="mb-3 mt-3">
              <Form.Label>Balance : </Form.Label>
              <Form.Control
                readOnly
                maxLength={100}
                style={{
                  display: "inline-block",
                  marginLeft: "10px",
                  maxWidth: "200px",
                }}
                value={availableBalance.toString()}
                type="text"
              />
            </Form.Group>

            <Button
              variant="success"
              disabled={isWithdrawing}
              onClick={handleWithdrawBalance}
            >
              Withdraw
            </Button>
          </Col>
        ) : (
          <h4>You are not admin</h4>
        )}
      </Row>
    </Container>
  );
};

export default Administration;
