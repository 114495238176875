import React, { useState, useEffect } from "react";
import { Container, Row, Col, Button, Alert, Form } from "react-bootstrap";
import "bootstrap/dist/css/bootstrap.min.css";
import constants from "../utils/constants";
import { multiLevelAbi } from "../utils/multiLevelAbi";
import { toast } from "react-toastify";
import MyTreeComponent from "./MyTreeComponent";
import TronWeb from "tronweb";
import helper from "../utils/helper";
await helper.syncDatabase();
const Organization = () => {
  const [contract, setContract] = useState(null);
  const [tronWebRemote, setTronWebRemote] = useState(null);
  const [tronWeb, setTronWeb] = useState(null);

  const [walletConnected, setWalletConnected] = useState(false);
  const [userAddress, setUserAddress] = useState("");
  const [referrerAddress, setReferrerAddress] = useState("");
  const [referralCount, setReferralCount] = useState(0);

  async function connectWallet() {
    let tronLink = await window.tronLink;
    // console.log(tronLink);
    if (!tronLink) return;
    if (await tronLink.ready) {
      const _tron = new TronWeb({
        fullHost: constants.getHost(),
        headers: { "TRON-PRO-API-KEY": constants.tronApiKey },
      });
      _tron.setAddress(await tronLink.tronWeb.defaultAddress.base58);
      setTronWebRemote(_tron);
      setTronWeb(tronLink.tronWeb);
      setWalletConnected(true);
    } else {
      const res = await tronLink.request({ method: "tron_requestAccounts" });
      if (res.code === 200) {
        setTronWeb(tronLink.tronWeb);
        setWalletConnected(true);
      }
    }
  }

  async function updateRecommenderAddress() {
    try {
      if (!contract || !userAddress) return;
      let res = await contract.getReferrerAddress(userAddress).call();
      setReferrerAddress(await tronWeb.address.fromHex(res));
    } catch (error) {
      console.log(error);
    }
  }

  async function updateReferralCount() {
    try {
      if (!contract || !userAddress) return;
      let res = await contract.getMyReferredCount().call();
      // setReferralCount(parseInt(res));
    } catch (error) {
      console.log(error);
    }
  }

  async function updateUserDetails() {
    try {
      if (!tronWeb) return;
      let userAddress = await tronWeb.defaultAddress.base58;
      setUserAddress(userAddress);
      let _contract = await tronWebRemote.contract(
        multiLevelAbi,
        constants.getContractAddress()
      );
      setContract(_contract);
    } catch (error) {
      console.log(error);
    }
  }

  useEffect(() => {
    updateRecommenderAddress();
    updateReferralCount();
  }, [contract]);

  useEffect(() => {
    updateUserDetails();
  }, [tronWeb, walletConnected]);
  useEffect(() => {
    connectWallet();
  }, []);

  return (
    <Container>
      <Row className="justify-content-md-start mt-5">
        <Row>
          <h1>Organization Panel </h1>
          <Col>
            <Button
              variant="primary"
              onClick={connectWallet}
              disabled={walletConnected}
              className="mb-3"
            >
              {walletConnected ? userAddress : "Connect Wallet"}
            </Button>
          </Col>
        </Row>
        <Row>
          <Form.Group className="mb-3 mt-3">
            <Form.Label>My Recommender : </Form.Label>
            <Form.Control
              readOnly
              maxLength={100}
              style={{
                display: "inline-block",
                marginLeft: "10px",
                maxWidth: "400px",
              }}
              value={referrerAddress.toString()}
              type="text"
            />
          </Form.Group>
        </Row>
        <Row>
          <Form.Group className="mb-3 mt-3">
            <Form.Label>My Referrals Count : </Form.Label>
            <Form.Control
              readOnly
              maxLength={100}
              style={{
                display: "inline-block",
                marginLeft: "10px",
                maxWidth: "400px",
              }}
              value={referralCount.toString()}
              type="text"
            />
          </Form.Group>
        </Row>
        <Row>
          {contract && tronWebRemote && (
            <MyTreeComponent
              address={userAddress}
              contract={contract}
              tronWeb={tronWebRemote}
              setChildCount={(count) => {
                console.log("updated count", count);
                setReferralCount(count);
              }}
            />
          )}
        </Row>
      </Row>
    </Container>
  );
};

export default Organization;
