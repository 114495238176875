// Navbar.js
import React from "react";
import { Navbar, Nav } from "react-bootstrap";
import { LinkContainer } from "react-router-bootstrap";

const NavBar = () => {
  return (
    <Navbar bg="light" variant="light" className="ps-3">
      <Navbar.Brand href="/">MultiLevel</Navbar.Brand>
      <Nav className="mr-auto">
        <LinkContainer to="/">
          <Nav.Link>User Panel</Nav.Link>
        </LinkContainer>
        <LinkContainer to="/organization">
          <Nav.Link>Organization</Nav.Link>
        </LinkContainer>
        <LinkContainer to="/others">
          <Nav.Link>Others</Nav.Link>
        </LinkContainer>
      </Nav>
    </Navbar>
  );
};

export default NavBar;
