import React, { useEffect, useRef, useState } from "react";
import Tree from "react-d3-tree";
import "./MyTreeComponent.css";
import constants from "../utils/constants";
const orgChart = {
  name: "CEO",
  children: [
    {
      name: "TSCe4dRgQ3MiktxBFtf2tJ4VPuDq2KudG2",
      attributes: {
        department: "Production",
      },
      children: [
        {
          name: "TSCe4dRgQ3MiktxBFtf2tJ4VPuDq2KudG2 ",
          attributes: {
            department: "Fabrication",
          },
          children: [
            {
              name: "Worker",
            },
          ],
        },
        {
          name: "TSCe4dRgQ3MiktxBFtf2tJ4VPuDq2KudG2",
          attributes: {
            department: "Assembly",
          },
          children: [
            {
              name: "Worker",
            },
          ],
        },
      ],
    },
  ],
};
const MyTreeComponent = ({ address, contract, tronWeb, setChildCount }) => {
  const [data, setData] = useState({});
  const [loading, setLoading] = useState(false);

  async function getChildList(_address) {
    let currentList = [];
    let totalChildCount = 0;
    let myChildren = await getReferralList(_address);
    for (let i = 0; i < myChildren.length; i++) {
      totalChildCount++;
      let item = myChildren[i];
      let childAddress = await tronWeb.address.fromHex(item);
      console.log(_address, childAddress);
      let trimmedAddress = childAddress.toString().substring(0, 7) + "...";
      let childList = await getChildList(childAddress);
      totalChildCount += childList.totalCount;
      currentList.push({
        name: trimmedAddress,
        // children: [],
        children: JSON.parse(JSON.stringify(childList.childrenList)),
      });
    }
    return { childrenList: currentList, totalCount: totalChildCount };
  }
  async function getReferralList(_address) {
    try {
      if (!address) return [];
      console.log("here", address);
      let res = await contract.getReferredList(_address).call();
      console.log("Get list response", res);
      return res[0];
    } catch (error) {
      console.log(error);
      return [];
    }
  }
  async function getData() {
    try {
      setLoading(true);
      if (!address) return;
      let topUser = await tronWeb.address.fromHex(
        await contract.topUser().call()
      );
      let _data = {};
      if (address == topUser) {
        let childData = await getChildList(
          "0x0000000000000000000000000000000000000000"
        );
        _data = {
          name: address,
          children: childData.childrenList,
        };
        setChildCount(childData.totalCount);
      } else {
        let childData = await getChildList(address);
        _data = {
          name: await tronWeb.address.fromHex(
            await contract.getReferrerAddress(address).call()
          ),
          children: [
            {
              name: address,
              children: childData.childrenList,
            },
          ],
        };
        setChildCount(childData.totalCount);
      }
      console.log("data", JSON.parse(JSON.stringify(_data)));
      setData(_data);
    } catch (error) {
      console.log(error);
    } finally {
      setLoading(false);
    }
  }
  useEffect(() => {
    console.log(data);
  }, [data]);
  useEffect(() => {
    getData();
  }, []);
  return (
    <div id="treeWrapper">
      {data && (
        <Tree
          orientation="vertical"
          transitionDuration={500}
          enableLegacyTransitions
          translate={{
            x: window.innerWidth / 2 - 100,
            y: 30,
          }}
          nodeSize={{
            x: 200,
            y: 100,
          }}
          data={data}
        />
      )}
    </div>
  );
};

export default MyTreeComponent;
