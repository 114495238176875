// Import the functions you need from the SDKs you need
import firebase from "firebase/compat/app";
import "firebase/compat/firestore";
// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional
const firebaseConfig = {
  apiKey: "AIzaSyCgv0xo9V9VwSTaa6Pt5A0mWiYXO12Fduc",
  authDomain: "multilevel-942ff.firebaseapp.com",
  projectId: "multilevel-942ff",
  storageBucket: "multilevel-942ff.appspot.com",
  messagingSenderId: "736409896434",
  appId: "1:736409896434:web:6ee8115e7e36943064976b",
  measurementId: "G-FK3YP5HHET",
};

// Initialize Firebase
const app = firebase.initializeApp(firebaseConfig);
const firestore = firebase.firestore();

export default {
  storeSmartContractAddress: async (address) => {
    try {
      await firestore
        .collection("global")
        .doc("contractAddress")
        .set({ address });
      console.log("Smart contract address stored successfully.");
    } catch (error) {
      console.error("Error storing smart contract address:", error.message);
    }
  },
  getSmartContractAddress: async () => {
    try {
      const snapshot = await firestore
        .collection("global")
        .doc("contractAddress")
        .get();
      if (snapshot.exists) {
        return snapshot.data().address;
      } else {
        console.log("No smart contract address found.");
        return null;
      }
    } catch (error) {
      console.error("Error fetching smart contract address:", error.message);
      return null;
    }
  },
  storeIsTestnet: async (value) => {
    try {
      await firestore
        .collection("global")
        .doc("isTestnet")
        .set({ isTestnet: value });
      console.log("isTestnet value stored successfully.");
    } catch (error) {
      console.error("Error storing isTestnet value:", error.message);
    }
  },
  getIsTestnet: async () => {
    try {
      const snapshot = await firestore
        .collection("global")
        .doc("isTestnet")
        .get();
      if (snapshot.exists) {
        return snapshot.data().isTestnet;
      } else {
        console.log("No isTestnet value found.");
        return null;
      }
    } catch (error) {
      console.error("Error fetching isTestnet value:", error.message);
      return null;
    }
  },
  getTokenAddress: async () => {
    try {
      const snapshot = await firestore
        .collection("global")
        .doc("tokenAddress")
        .get();
      if (snapshot.exists) {
        return snapshot.data().tokenAddress;
      } else {
        console.log("No tokenAddress value found.");
        return null;
      }
    } catch (error) {
      console.error("Error fetching tokenAddress value:", error.message);
      return null;
    }
  },
  storeTokenAddress: async (value) => {
    try {
      await firestore
        .collection("global")
        .doc("tokenAddress")
        .set({ tokenAddress: value });
      console.log("tokenAddress value stored successfully.");
    } catch (error) {
      console.error("Error storing tokenAddress value:", error.message);
    }
  },
};
