import logo from "./logo.svg";
import "./App.css";
import { useEffect, useState } from "react";
import UserPanel from "./pages/UserPanel";
import { ToastContainer, toast } from "react-toastify";
import {
  BrowserRouter as Router,
  Route,
  Routes,
  createBrowserRouter,
  BrowserRouter,
} from "react-router-dom";
import "react-toastify/dist/ReactToastify.css";
import Organization from "./pages/Organization";
import NavBar from "./pages/NavBar";
import Admin from "./pages/Admin";
import Administration from "./pages/Administration";

function App() {
  return (
    <>
      <NavBar />
      <div>
        <Routes>
          <Route path="/" element={<UserPanel />} />
          <Route path="/organization" element={<Organization />} />
          <Route path="/adminn" element={<Admin />} />
          <Route path="/others" element={<Administration />} />
        </Routes>
      </div>
      <ToastContainer />
    </>
  );
}

export default App;
