import React, { useEffect, useState } from "react";
import { Container, Row, Col, Button, Alert, Form } from "react-bootstrap";
import constants from "../utils/constants";
import { toast } from "react-toastify";
import controller from "../utils/firebase";
import helper from "../utils/helper";
await helper.syncDatabase();
const Admin = () => {
  const [contractAddress, setContractAddress] = useState(
    constants.getContractAddress()
  );
  const [tokenAddress, setTokenAddress] = useState(constants.getTokenAddress());
  const [isTestNet, setIsTestNet] = useState(constants.getIsTestnet());

  const [isUpdating, setIsUpdating] = useState(false);

  const updateDetails = async () => {
    try {
      setIsUpdating(true);
      if (!tokenAddress || !contractAddress) {
        toast.info("Fill in all details");
        return;
      }
      await controller.storeIsTestnet(isTestNet);
      await controller.storeSmartContractAddress(contractAddress);
      await controller.storeTokenAddress(tokenAddress);
      toast.info("Details updated");
    } catch (error) {
      console.log(error);
      toast.error(error.toString());
    } finally {
      setIsUpdating(false);
    }
  };

  return (
    <Container>
      <Row className="justify-content-md-start mt-5">
        <Row>
          <h1>Admin Panel</h1>
        </Row>
        <Row>
          <Form.Group className="mb-3 mt-3">
            <Form.Label>Contract Address: </Form.Label>
            <Form.Control
              maxLength={100}
              style={{
                display: "inline-block",
                marginLeft: "10px",
                maxWidth: "400px",
              }}
              value={contractAddress}
              type="text"
              onChange={(event) => {
                setContractAddress(event.target.value);
              }}
            />
          </Form.Group>
        </Row>
        <Row>
          <Form.Group className="mb-3 mt-3">
            <Form.Label>Token Address: </Form.Label>
            <Form.Control
              maxLength={100}
              style={{
                display: "inline-block",
                marginLeft: "10px",
                maxWidth: "400px",
              }}
              value={tokenAddress}
              type="text"
              onChange={(event) => {
                setTokenAddress(event.target.value);
              }}
            />
          </Form.Group>
        </Row>
        <Row>
          <Form.Group className="mb-3 mt-3">
            <Form.Label>Testnet </Form.Label>
            <Form.Check // prettier-ignore
              style={{
                display: "inline-block",
                marginLeft: "10px",
                maxWidth: "400px",
              }}
              type="switch"
              id="custom-switch"
              checked={isTestNet}
              onChange={(event) => {
                setIsTestNet(event.target.checked);
              }}
            />
          </Form.Group>
        </Row>
        <Row>
          <Button
            variant="primary"
            onClick={updateDetails}
            disabled={isUpdating}
            className="mb-3"
          >
            Update
          </Button>
        </Row>
      </Row>
    </Container>
  );
};

export default Admin;
